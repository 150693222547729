export const moduleRoutes = {
  root: 'eu-txns',
  childForms: {
    endUserWithdrawal: {
      data: { title: 'Retiros' },
      requireAuth: false,
      functions: [],
      route: 'endUserWithdrawal',
    },
    endUserDeposit: {
      data: { title: 'Consignaciones' },
      requireAuth: false,
      functions: [],
      route: 'endUserDeposit',
    },
    endUserCreditCard: {
      data: { title: 'Tarjeta de crédito' },
      requireAuth: false,
      functions: [],
      route: 'endUserCreditCard',
    },
    endUserCollectionCategory: {
      data: { title: 'Categorías Recaudos' },
      requireAuth: false,
      functions: [],
      route: 'endUserCollectionCategory',
    },
    endUserAguapenCollection: {
      data: { title: 'Recaudo aguapen' },
      requireAuth: false,
      functions: [],
      route: 'endUserAguapenCollection',
    },
    endUserAmaguaCollection: {
      data: { title: 'Recaudo amagua' },
      requireAuth: false,
      functions: [],
      route: 'endUserAmaguaCollection',
    },
    endUserAtmCollection: {
      data: { title: 'Recaudo atm' },
      requireAuth: false,
      functions: [],
      route: 'endUserAtmCollection',
    },
    endUserBelcorpCollection: {
      data: { title: 'Recaudo belcorp' },
      requireAuth: false,
      functions: [],
      route: 'endUserBelcorpCollection',
    },
    endUserClaroCollection: {
      data: { title: 'Recaudo claro' },
      requireAuth: false,
      functions: [],
      route: 'endUserClaroCollection',
    },
    endUserClaroTopUp: {
      data: { title: 'Recarga claro' },
      requireAuth: false,
      functions: [],
      route: 'endUserClaroTopUp',
    },
    endUserMovistarPackageTopUp: {
      data: { title: 'Paquetes movistar' },
      requireAuth: false,
      functions: [],
      route: 'endUserMovistarPackageTopUp',
    },
    endUserClaroPackageTopUp: {
      data: { title: 'Paquetes claro' },
      requireAuth: false,
      functions: [],
      route: 'endUserClaroPackageTopUp',
    },
    endUserTuentiTopUp: {
      data: { title: 'Recarga Tuenti' },
      requireAuth: false,
      functions: [],
      route: 'endUserTuentiTopUp',
    },
    endUserTuentiPackageTopUp: {
      data: { title: 'Paquete tuenti' },
      requireAuth: false,
      functions: [],
      route: 'endUserTuentiPackageTopUp',
    },
    endUserCntTopUp: {
      data: { title: 'Recarga CNT' },
      requireAuth: false,
      functions: [],
      route: 'endUserCntTopUp',
    },
    endUserCnelCollection: {
      data: { title: 'Recaudo cnel' },
      requireAuth: false,
      functions: [],
      route: 'endUserCnelCollection',
    },
    endUserCntCollection: {
      data: { title: 'Recaudo cnt' },
      requireAuth: false,
      functions: [],
      route: 'endUserCntCollection',
    },
    endUserCteCollection: {
      data: { title: 'Recaudo cte' },
      requireAuth: false,
      functions: [],
      route: 'endUserCteCollection',
    },
    endUserDepratiCollection: {
      data: { title: 'Recaudo deprati' },
      requireAuth: false,
      functions: [],
      route: 'endUserDepratiCollection',
    },
    endUserDirectvCollection: {
      data: { title: 'Recaudo directv' },
      requireAuth: false,
      functions: [],
      route: 'endUserDirectvCollection',
    },
    endUserDirectvPostpaidCollection: {
      data: { title: 'Recaudo directv postpago' },
      requireAuth: false,
      functions: [],
      route: 'endUserDirectvPostpaidCollection',
    },
    endUserEmaapqCollection: {
      data: { title: 'Recaudo emaapq' },
      requireAuth: false,
      functions: [],
      route: 'endUserEmaapqCollection',
    },
    endUserEtapaCollection: {
      data: { title: 'Recaudo etapa' },
      requireAuth: false,
      functions: [],
      route: 'endUserEtapaCollection',
    },
    endUserGuayaquilCharityCollection: {
      data: { title: 'Junta beneficencia GYE' },
      requireAuth: false,
      functions: [],
      route: 'endUserGuayaquilCharityCollection',
    },
    endUserIessCollection: {
      data: { title: 'Recaudo iess' },
      requireAuth: false,
      functions: [],
      route: 'endUserIessCollection',
    },
    endUserInteraguaCollection: {
      data: { title: 'Recaudo interagua' },
      requireAuth: false,
      functions: [],
      route: 'endUserInteraguaCollection',
    },
    endUserMeerCollection: {
      data: { title: 'Recaudo meer' },
      requireAuth: false,
      functions: [],
      route: 'endUserMeerCollection',
    },
    endUserMovistarCollection: {
      data: { title: 'Recaudo movistar' },
      requireAuth: false,
      functions: [],
      route: 'endUserMovistarCollection',
    },
    endUserSriCollection: {
      data: { title: 'Recaudo sri' },
      requireAuth: false,
      functions: [],
      route: 'endUserSriCollection',
    },
    endUserTvcableCollection: {
      data: { title: 'Recaudo tvcable' },
      requireAuth: false,
      functions: [],
      route: 'endUserTvcableCollection',
    },
    endUserYanbalCollection: {
      data: { title: 'Recaudo yanbal' },
      requireAuth: false,
      functions: [],
      route: 'endUserYanbalCollection',
    },
    endUserPyccaCollection: {
      data: { title: 'Recaudo Pycca' },
      requireAuth: false,
      functions: [],
      route: 'endUserPyccaCollection',
    },
    endUserNetlifeCollection: {
      data: { title: 'Recaudo Netlife' },
      requireAuth: false,
      functions: [],
      route: 'endUserNetlifeCollection',
    },
    endUserSchoolPayment: {
      data: { title: 'Pago de colegios' },
      requireAuth: false,
      functions: [],
      route: 'endUserSchoolPayment',
    },
    // Municipios
    endUserDauleCollection: {
      data: { title: 'Pagos Daule' },
      requireAuth: false,
      functions: [],
      route: 'endUserDauleCollection'
    },
    endUserGuayaquilCollection: {
      data: { title: 'Pagos Guayaquil' },
      requireAuth: false,
      functions: [],
      route: 'endUserGuayaquilCollection'
    },
    endUserQuitoCollection: {
      data: { title: 'Pagos Quito' },
      requireAuth: false,
      functions: [],
      route: 'endUserQuitoCollection'
    },
    endUserSalinasCollection: {
      data: { title: 'Pagos Salinas' },
      requireAuth: false,
      functions: [],
      route: 'endUserSalinasCollection'
    },
    endUserSamborondonCollection: {
      data: { title: 'Pagos Samborondón' },
      requireAuth: false,
      functions: [],
      route: 'endUserSamborondonCollection'
    },
    endUserSantaElenaCollection: {
      data: { title: 'Pagos Santa Elena' },
      requireAuth: false,
      functions: [],
      route: 'endUserSantaElenaCollection',
    },
    endUserCuencaCollection: {
      data: { title: 'Pagos Cuenca' },
      requireAuth: false,
      functions: [],
      route: 'endUserCuencaCollection',
    },
    endUserTeletagTopUp: {
      data: { title: 'Recaudos Teletag' },
      requireAuth: false,
      functions: [],
      route: 'endUserTeletagTopUp',
    },
    // Pagos
    endUserSupplierPayment: {
      data: { title: 'Recaudo moneyT' },
      requireAuth: true,
      functions: [],
      route: 'endUserSupplierPayment',
    },
    endUserThirdPartyPayment: {
      data: { title: 'Recaudo moneyT' },
      requireAuth: true,
      functions: [],
      route: 'endUserThirdPartyPayment',
    },
    endUserCodeHistory: {
      data: { title: 'Historial' },
      requireAuth: true,
      functions: [],
      route: 'endUserCodeHistory',
    },
    endUserFavTxns: {
      data: { title: 'Favoritos' },
      requireAuth: true,
      functions: [],
      route: 'endUserFavTxns',
    },
    endUserRegister: {
      data: { title: 'Registro nuevo usuario' },
      requireAuth: false,
      functions: [],
      route: 'endUserRegister',
    },
    endUserHomeRedirect: {
      data: { title: 'Inicio' },
      requireAuth: false,
      functions: [],
      route: 'endUserHomeRedirect',
    },
    endUserNearAgents: {
      data: { title: 'Ubicación' },
      requireAuth: false,
      functions: [],
      route: 'endUserNearAgents',
    },
    endUserAgentView: {
      data: { title: 'Inicio' },
      requireAuth: false,
      functions: [],
      route: 'endUserAgentView',
    },
  },
  layout: 'eumain',
  loadChildren: () => import('src/app/page-modules/end-user/eu-transactions/eu-transaction.module').then(m => m.EndUserTransactionsModule),
};
