import * as uuid from 'uuid';
import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService, NzNotificationDataOptions } from 'ng-zorro-antd/notification';
import { NzImageService } from 'ng-zorro-antd/image';

import { BasicFormComponent } from 'tuain-ng-forms-lib';

import { FormOperationService } from 'src/app/services/form-manager.service';
import { EventManagerService } from 'src/app/services/event-manager.service';
import { FileManagementService } from 'src/app/services/file-management.service';
import { GeocodeService } from 'src/app/services/geocode.service';
import { GeocoderResponse } from 'src/app/models/geocoder-response';
import { AppLoggerService } from 'src/app/services/app-logger.service';
import { AppConfigService } from 'src/app/services/app-configuration.service';
import { APIGatewayService } from 'src/app/services/api-gateway.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { IconDictionaryService } from 'src/app/services/icon-dictionary.service';
import { LiveConnectionService } from 'src/app/services/live-connection.service';
import { ComponentPageService } from 'src/app/services/component-pages.service';
import { StorageService } from 'src/app/services/storage.service';

const SESSION_TOBE_CLOSED = 'sessionToBeClosed';
const CONFIG_LOADED = 'configLoaded';

import {
  StandardModalComponent,
  UploadModalComponent,
} from 'src/app/components/tuain/forms/modals/standard-modals.component';

import { formConfig } from 'src/app/components/tuain/forms.module.config';

export interface ModalOption {
  name: string;
  style: string;
}
interface SectionModal {
  visible: boolean;
  title: string;
  code: string;
  actions: any;
  callback: any;
}

interface SimpleModal {
  visible: boolean;
  title: string;
  content: string;
  template: any;
  type: string;
  options: ModalOption[];
  callback: (i: number) => void;
}

@Component({ template: '' })
export class AppFormComponent extends BasicFormComponent implements OnInit, OnDestroy {
  assetsBase: string;
  visibleHeaderActions: any[] = [];
  initialStates: string[] = [];
  viewGoBack: boolean = true;
  debug: boolean = false;
  mobile: boolean = false;
  showMenu: boolean = false;
  tabType: String = 'line';
  tabPosition: String = 'top';
  layout: string;
  currentLocationAddress: string = null;
  eventSubscriptions: any[] = [];
  currentAlert: any = null;
  currentError: any = null;
  _showServerErrorPopup = true;
  headerBadgeCounter: number = 0;
  statusBarHeight: number = 36;
  showRefresher: boolean = false;

  pageClass: string = '';

  sectionModal: SectionModal = {
    visible: false,
    title: '',
    code: '',
    actions: null,
    callback: null,
  };

  simpleModal: SimpleModal = {
    visible: false,
    title: '',
    type: 'text',
    content: null,
    template: null,
    options: null,
    callback: null,
  };

  // form: FormGroup;
  headerBackground = '1';
  modalDialogs: any = {};

  protected _route: ActivatedRoute;
  protected _router: Router;
  protected _appLogger: AppLoggerService;
  protected _appConfig: AppConfigService;
  protected _apiGateway: APIGatewayService;
  protected _userSession: UserSessionService;
  protected _storage: StorageService;
  protected _liveConnection: LiveConnectionService;
  protected _iconDictionary: IconDictionaryService;
  protected _componentPageService: ComponentPageService;
  protected _geocode: GeocodeService;
  protected _modalService: NzModalService;
  protected _notificationService: NzNotificationService;
  protected _message: NzMessageService;
  protected _nzImageService: NzImageService;
  constructor(injector: Injector) {
    super(
      injector.get(FormOperationService),
      injector.get(EventManagerService),
      injector.get(FileManagementService),
    );
    this.setConfig(formConfig);
    this._route = injector.get(ActivatedRoute);
    this._router = injector.get(Router);
    this._appLogger = injector.get(AppLoggerService);
    this._appConfig = injector.get(AppConfigService);
    this._apiGateway = injector.get(APIGatewayService);
    this._userSession = injector.get(UserSessionService);
    this._storage = injector.get(StorageService);
    this._liveConnection = injector.get(LiveConnectionService);
    this._iconDictionary = injector.get(IconDictionaryService);
    this._componentPageService = injector.get(ComponentPageService);
    this._geocode = injector.get(GeocodeService);
    this._modalService = injector.get(NzModalService);
    this._notificationService = injector.get(NzNotificationService);
    this._message = injector.get(NzMessageService);
    this._nzImageService = injector.get(NzImageService);
    // Flujo personalizado
    this.layout = this.formConfig.defaultFieldsLayout;
    this.formRoute = this._componentPageService.formRoute(this.formCode);
    this.debug = this._appConfig.getParameter('debug') ?? false;
    this.mobile = this._appConfig.getParameter('mobile') ?? false;
  }

  override start() {
    this.initialStates = [this.states?.[0]] ?? [];
    this.subscribeAppEvent(CONFIG_LOADED, () => this.assetsBase = this._appConfig.getParameter('assetsBase'));
    this.onFormChange(() => this.customizeHeaderActions());
    this.customizeHeaderActions();
  }

  customizeHeaderActions() {
    this.visibleHeaderActions = this.getHeaderActions().filter?.(item => item?.visible) ?? [];
  }

  log(message) {
    this._appLogger.log(message);
  }

  override goBackForm(forced = false): void {
    if (forced || this.form?.initialStates?.includes(this.state)) {
      super.goBackForm();
    }
  }

  subscribeAppEvents(eventClass, callback) {
    this.eventSubscriptions.push(this._eventManager.subscribe(eventClass, eventData => callback(eventData)));
  }

  emitAppEvents(eventClass, eventData) {
    this._eventManager.next(eventClass, eventData);
  }

  async showLoading() { }

  setTabType(tabType: String) {
    // card | line
    this.tabType = tabType;
  }

  setTabPosition(tabPosition: String) {
    this.tabPosition = tabPosition;
  }

  setHorizontal(layout: string) {
    this.layout = layout;
  }

  get elements() {
    const allElements = [];
    const allSections = this.getSections();
    for (const section of allSections) {
      const subsections = section.subSections;
      for (const subsection of subsections) {
        for (const element of subsection.subSectionElements) {
          allElements.push(element);
        }
      }
    }
    return allElements;
  }

  closeForm() {
    this.closeCurrentAlert();
    this.hideSectionModalDialog();
    this.eventSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit() {
    this.hideSectionModalDialog();
    this._route.params.subscribe((params) => {
      this.cleanStart();
      this.formInit(params);
    });
    this.subscribeAppEvents(SESSION_TOBE_CLOSED, () => {
      this.closeCurrentAlert();
      this.hideSectionModalDialog();
    });
    this.cleanActionServerError();
    this.cleanFieldServerError();
    this.cleanTableServerError();
    this.onActionServerError(() => this.displayActionServerError());
    this.onValidationServerError(() => this.displayValidationServerError());
    this.onTableServerError(() => this.displayTableServerError());
  }

  ngOnDestroy() {
    this.closeForm();
  }

  getCurrentPosition() {
    return this._geocode.getCurrentPosition();
  }

  override displayActionServerError() {
    if (this._showServerErrorPopup) {
      this.showTitledMessage(
        this.errorMessage, // 'SERVERPROCESS',
        this.errorDetail.slice(0, 100),
        'error', // this.formConfig.errorTypes.error,
      );
    }
  }

  disableServerErrorPopup() {
    this._showServerErrorPopup = false;
  }

  enableServerErrorPopup() {
    this._showServerErrorPopup = true;
  }

  override displayValidationServerError() {
    // 'success' 'info' 'warning' 'error'
    const notificationType = 'error';
    this._notificationService.create(notificationType, this.errorMessage, this.errorDetail);
  }

  override displayTableServerError() {
    this.showTitledMessage(
      // 'SERVERPROCESS',
      this.errorMessage,
      this.errorDetail,
      this.formConfig.errorTypes.error,
    );
  }

  async showInformationModal(type, title, body, options = null) {
    /** 
     * type: info, success, error, warning,
     * options permite soportar configuraciones adicionales como textos, callback y demás
     */
    let actionText = 'Aceptar';
    const modalFunction = this._modalService?.[type] ?? this._modalService?.info;
    const additionalOptions: any = {};
    if (options) {
      // En la medida de mas opciones, se incluyen aqui:
      const { callback, okButtonText } = options;
      okButtonText && (actionText = okButtonText);
      callback && (additionalOptions.nzOnOk = callback);
    }
    const modalObject: any = { nzTitle: title, nzContent: body, nzOkText: actionText, ...additionalOptions };
    modalFunction(modalObject);
  }

  showSectionModalDialog(code, title, actionsSubSection, callback = null): void {
    this.sectionModal.code = code;
    this.sectionModal.title = title;
    this.sectionModal.visible = true;
    this.sectionModal.actions = this.getSubSection(code, actionsSubSection);
    if (callback && typeof callback === 'function') {
      this.sectionModal.callback = callback;
    }
    this.hideSubSection(code, actionsSubSection);
  }

  hideSectionModalDialog() {
    const callback = this.sectionModal.callback;
    if (callback) {
      callback();
    }
    this.sectionModal.visible = false;
  }

  showSimpleModalDialog(title: string, content: string, template: any, options: ModalOption[],
    callback: (i: number) => void, type = 'text'): void {
    this.simpleModal.title = title;
    this.simpleModal.content = content;
    this.simpleModal.template = template;
    this.simpleModal.type = type;
    this.simpleModal.visible = true;
    this.simpleModal.options = options;
    this.simpleModal.callback = (i) => { this.simpleModal.visible = false; callback(i); };
  }

  hideSimpleModalDialog() { this.simpleModal.visible = false; }

  override async showModalDialog(title, body, options, callback?, parameters?) {
    const params = { title, body, options, ...parameters };
    params.body = body || params?.body;
    params.options = params.options ?? ['Aceptar'];
    const autoClose = params?.autoClose ?? true;

    const modalName = uuid.v4();
    this.modalDialogs[modalName]?.close();
    this.modalDialogs[modalName] = null;
    const footerOptions = [];
    for (let index = 0; index < params.options.length; index++) {
      const option = params.options[index];
      const footerOption = typeof option === 'string' ? { label: option } : { ...option };
      footerOption.type = footerOption.type ?? 'primary';
      footerOption.disabled = footerOption.disabled ?? false;
      footerOption.onClick = () => {
        if (callback) {
          callback(index, this.modalDialogs[modalName]);
        }
        if (autoClose) {
          this.modalDialogs[modalName]?.close();
          this.modalDialogs[modalName] = null;
        }
      };
      footerOptions.push(footerOption);
    }
    const modalInstance: NzModalRef = this._modalService.create({
      nzTitle: params.title,
      nzContent: StandardModalComponent,
      nzFooter: footerOptions,
    });
    this.modalDialogs[modalName] = modalInstance;
    modalInstance?.getContentComponent()?.setContent(params);
  }

  async closeCurrentAlert() {
    if (this.currentAlert) {
      await this.currentAlert.dismiss();
      this.currentAlert = null;
    }
  }

  override openUploadDialog(title, body, options, callback?, parameters?): void {
    const params = { title, body, options, ...parameters };
    params.body = body || params?.body;
    params.options = params.options ?? ['Aceptar'];
    const autoClose = params?.autoClose ?? true;

    const modalName = uuid.v4();
    this.modalDialogs[modalName]?.close();
    this.modalDialogs[modalName] = null;
    const footerOptions = [];
    for (let index = 0; index < params.options.length; index++) {
      const option = params.options[index];
      const footerOption = typeof option === 'string' ? { label: option } : { ...option };
      footerOption.type = footerOption.type ?? 'primary';
      footerOption.disabled = footerOption.disabled ?? false;
      footerOption.onClick = () => {
        const modalInstance = this.modalDialogs[modalName];
        modalInstance?.getContentComponent()?.getFiles((fileContentObj) => {
          if (callback) {
            callback(index, fileContentObj, this.modalDialogs[modalName]);
          }
          if (autoClose) {
            this.modalDialogs[modalName]?.close();
            this.modalDialogs[modalName] = null;
          }
        });
      };
      footerOptions.push(footerOption);
    }
    const modalInstance: NzModalRef = this._modalService.create({
      nzTitle: params.title,
      nzContent: UploadModalComponent,
      nzFooter: footerOptions,
    });
    this.modalDialogs[modalName] = modalInstance;
    modalInstance?.getContentComponent()?.setContent(params);
  }

  override showFieldInfo(fieldCode: string, detail?: any): void {
    if (!detail) {
      const fieldInfo = this.getField(fieldCode)?.info;
      if (!fieldInfo) {
        return;
      }
      let infoTitle = '';
      let infoDetail = '';
      try {
        const info = JSON.parse(JSON.stringify(fieldInfo));
        infoTitle = info.infoTitle ?? '';
        infoDetail = info.infoDetail ?? '';
      } catch (e) {
        return;
      }
      this.showModalDialog(infoTitle, infoDetail, ['Cerrar'], null);
    }
  }

  async showMessage(message, type = 'success', options: any = {}) {
    message && this.showTitledMessage(message, '', type, options);
  }

  async showTitledMessage(title, message, type = 'success', options: any = {}) {
    const cssClass = options?.cssClass ?? 'toastCustom';
    const icon = options?.icon ?? '';
    const toastOptions: NzNotificationDataOptions = {
      nzDuration: 8000,
      nzPlacement: 'top',
      nzStyle: { 'font-weight': 'bold' },
    };
    this._notificationService.create(type, title, message, toastOptions);
  }

  showNotifyPopup(title, message, type = 'success') {
    this.showTitledMessage(title, message, type);
  }

  showNotifyPopupAlert(title, message, type = 'success') {
    this.showTitledMessage(title, message, type);
  }

  canChangeSection(fromIndex: number, toIndex: number) { }

  async geocodeLocation(geolocationField, addressField) {
    const geoLocation = this.getFieldValue(geolocationField);
    const point: google.maps.LatLngLiteral = {
      lat: geoLocation[0],
      lng: geoLocation[1],
    };
    this._geocode.geocodeLatLng(point)
      .then((response: GeocoderResponse) => {
        if (response.status === 'OK' && response.results?.length) {
          const value = response.results[0];
          this.setFieldValue(addressField, value.formatted_address);
        }
      });
  }

  async geoCodeAddress(addressFields, geolocationField, addressGeocodeField) {
    const fieldsToGeoCode = this.getFieldValue(addressFields);
    let locationAddress = 'Ecuador';
    if (fieldsToGeoCode && Array.isArray(fieldsToGeoCode)) {
      fieldsToGeoCode.forEach((fieldName) => {
        const fieldObj = this.getField(fieldName);
        const text = fieldObj?.getOptionText()
        const value = fieldObj?.getValue()
        locationAddress += `, ${text ?? value}`;
      });
    }
    if (this.currentLocationAddress === locationAddress) {
      return;
    }
    this.currentLocationAddress = locationAddress;
    this._geocode.getLocation(this.currentLocationAddress)
      .subscribe((response: GeocoderResponse) => {
        if (response.status === 'OK' && response.results?.length) {
          const location = response.results[0];
          const loc: any = location.geometry.location;
          const currentLocation = this.getFieldValue(geolocationField);
          if (currentLocation[0] !== loc.lat || currentLocation[0] !== loc.lng) {
            this.setFieldValue(geolocationField, [loc.lat, loc.lng]);
            this.setFieldValue(addressGeocodeField, location.formatted_address);
          }
        }
      });
  }

  enableActionUponFields(fields: string[] | string, actions: string[] | string, options: any = {}): boolean {
    const {
      disableIfNotChanged = false,
      onlyVisible = false,
      checkCompleteness = false,
    } = options;
    const fieldCodes = Array.isArray(fields) ? fields : [fields];
    const actionCodes = Array.isArray(actions) ? actions : [actions];
    const fieldsWithError = this.getFieldsWithValidationIssues(fieldCodes, null, null, onlyVisible);
    const emptyFields = this.getRequiredEmptyFields(fieldCodes, null, null, onlyVisible);
    let incompleteFields = [];
    if (checkCompleteness) {
      incompleteFields = this.getFieldSet(fld => ((!onlyVisible || fld?.visible) && fld._minLength)
        ? (fld.value?.length < fld._minLength) : false, fieldCodes, null, null);
    }
    let canEnable = !(fieldsWithError?.length > 0 || emptyFields?.length > 0 || incompleteFields?.length > 0);
    if (disableIfNotChanged) {
      const changedFields = this.getChangedFields(fieldCodes);
      canEnable &&= changedFields.length > 0;
    }
    actionCodes.forEach((action) => {
      if (canEnable) {
        this.enableAction(action);
      } else {
        this.disableAction(action);
      }
    });
    return canEnable;
  }

  async presentToast(message, error = false, icon = 'chevron-down-circle-sharp', toasPosition = null, duration = 5000) {
  }

  setHeaderBadgeCounter(value: number = 0) {
    this.headerBadgeCounter = value;
  }

  imagePreview(image) {
    this._nzImageService.preview([{ src: image }], { nzZoom: 0.8, nzRotate: 0 });
  }

  print(pdf) {
    pdf?.getData().then((u8) => {
      let blob = new Blob([u8.buffer], {
        type: 'application/pdf'
      });
      const blobUrl = window.URL.createObjectURL((blob));
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.onload = () => {
        iframe.focus();
        iframe.contentWindow.print();
      };
      this.hideSectionModalDialog();
    });
  }
}
